/* eslint-disable no-unused-vars */

import "core-js/stable";
import globalThisProperty from "core-js/stable/global-this"; // For Safari 11
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import "intersection-observer";
import "mutation-events";
import "@webcomponents/custom-elements";

import { install as installResizeObserver } from "resize-observer";

// This is required specifically for Chart JS.
// Other resize Observer polyfills don't work on canvas elements
installResizeObserver();


// replaceChildren is needed by turbo as of turbo 7.2.0 https://github.com/hotwired/turbo/pull/534
// replaceChildren is only available natively in safari 14+
// This is taken from https://github.com/zloirock/core-js/issues/940#issuecomment-1115872340

function replaceChildren(...newChildren) {
  const { childNodes } = this;
  while (childNodes.length) {
    childNodes[0].remove();
  }
  this.append(...newChildren);
}

Document.prototype.replaceChildren ||= replaceChildren;
DocumentFragment.prototype.replaceChildren ||= replaceChildren;
Element.prototype.replaceChildren ||= replaceChildren;
